<template>
  <div>
    <div class="routine_finder__modal" :class="this.popupLoad ? 'fade-in' : 'fade-out'" ref="popupElement">
      <div v-if="this.loadingIcon" class="loader-container">
        <div class="loadMore-trigger"></div>
        <transition name="fade">
          <div class="loader-css">Loading</div>
        </transition>
      </div>
      <div class="routine_finder__modal__innerWrapper">
       
         <routine-finder-header :headerdata="this.headerData" v-if="this.popupBannerVisible"></routine-finder-header>
        <routine-finder-question-answer v-if="this.questionAnswerStart"></routine-finder-question-answer>
        <routing-finder-banner :bannerdata="this.bannerData" v-if="this.routineBanner"></routing-finder-banner>
        <routine-finder-result-page v-if="resultPage"></routine-finder-result-page>
      </div>
    </div>
  </div>
</template>

<script>
import routineFinderHeader from '@Feature/RoutineFinder/code/Scripts/components/routine-finder-header/routine-finder-header.vue';
import routineFinderQuestionAnswer from '@Feature/RoutineFinder/code/Scripts/components/routine-finder-questions-answer/routine-finder-question-answer.vue';
import routingFinderBanner from '@Feature/RoutineFinder/code/Scripts/components/routine-finder-banner/routing-finder-banner.vue';
import routineFinderResultPage from '@Feature/RoutineFinder/code/Scripts/components/routine-finder-result-page/routine-finder-result-page.vue';
let bodyTag = document.querySelector('body')
export default {
  name: 'routine-finder',
  components: { routineFinderHeader, routineFinderQuestionAnswer, routingFinderBanner, routineFinderResultPage},
  props: {
    desktopLabel: {
      type: String, required: false, default: ''
    },
    mobileLabel: {
      type: String, required: false, default: ''
    }, 
  },
  data() {
    return {
      questionAnswerStart: false,
      routineBanner: false,
      popupBannerVisible: false,
      popupLoad: false,
      apiData: '',
      headerData: '',
      bannerData: '',
      loadingIcon: true,
      resultPage: false,
     
    }
  },
  mounted() {
    this.popupInit();
    window.eventBus.$on('startOverApp:on', (value) => {
      this.$store.commit('resetQuestionAnsDatas');
      this.resultPage = false;
      this.popupBannerVisible = true;
      this.questionAnswerStart = true;

    });
    window.eventBus.$on('routineFinderresultpage:on', (value) => {
      this.popupBannerVisible = false; 
      this.routineBanner = false;
      this.questionAnswerStart = false;
      this.resultPage = true
    });
    window.eventBus.$on('routineFinderLoadingScreen:on', (value) => {
      this.popupBannerVisible = true; 
    });
    window.eventBus.$on('letstartCta:on', (value) => {
      this.questionAnswerStart = value;
      this.routineBanner = false;
    });
    window.eventBus.$on('routineFinderClose:on', (value) => {
      this.popupBannerVisible = value;
      this.popupLoad = value;
      this.questionAnswerStart = value;
      bodyTag.classList.remove("noScroll");
    });
    let elements = this.$refs.popupElement
    elements.addEventListener('animationend', () => {
      setTimeout(() => {
        elements.classList.add("animationend")
      }, 300);
    });

  },
  methods: {
    popupInit() {
      this.fetchData();
      this.popupLoad = true;
      if (bodyTag) {
        bodyTag.classList.add("noScroll");
      }
    },
    async fetchData() {
      const apiURL = '/api/routinefinder/RoutineFinderApiBus';
      const jsonData = {
        "Stage": 1
      };
      try {
        const response = await fetch(apiURL, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json;charset=utf-8' },
          body: JSON.stringify(jsonData),
        });
        if (response.ok) {
          const data = await response.json(); // Convert response to JSON  
          this.apiData = data.data;
          this.headerData = this.apiData?.headerData;
          this.bannerData = this.apiData?.initialBanner;
          this.loadingIcon = false;
          this.popupBannerVisible = true;
          this.routineBanner = true;
          this.$store.dispatch('apicall');

        }
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    }
  }

}
</script>
<style lang="scss" src="./routine-finder.scss"></style>