<template>
  <div class="routine-finder-commerce-connector">
    <div class="routine-finder-commerce-connector__cta">
      <button v-truncate-rich-text="{ limit: 12, ellipse: false, singleLine: true }" @click.stop="openRetailer()"
        class="buyBtn">{{ ctaLable }}</button>
    </div>
    <div class="commerce-connector" @click.stop>
      <div class="popupContainerOuter showPopup" id="commerceconnectorpopup">
        <div class="popupContainer">
          <div class="popupContainer__close" @click.stop="closePopupScreen()">&#10006;</div>
          <div class="container" id="app">
            <div class="tabs">
              <ul>
                <li v-for="(tab, index) in eretailer" :class="{ 'is-active': show == index }"
                  @click.prevent="show = index" :key="index">
                  <a>{{ tab.countryCode }}</a>
                </li>
              </ul>
            </div>
            <div class="tabData">
              <transition-group name="fade-up" target="div" appear>
                <div v-for="(tab, index) in eretailer" v-if="show == index" :key="index + 0">
                  <div class="popupContainer__inner" id="commerceConnectWrapper" v-if="tab.shopsUnavailable == false">
                    <div class="vue-slick-carousel-wrapper" @click.stop>
                      <VueSlickCarousel ref="carousel" :arrows="false" :dots="true" v-bind="settings"
                        v-if="tab.shops.length > 1">
                        <div class="popupContainer__item desktop" v-if="!smallScreen" v-for="shop in tab.shops"
                          :key="shop.id">
                          <a :href="shop.link" target="_blank" class="popupContainer__item_link"
                            @click="setAnalytics(shop.cartTagging)">
                            <div class="popupContainer__image">
                              <img alt="shop" :src="shop.logo">
                            </div>
                            <div class="popupContainer__bagIcon">
                              <img alt="bagblack" src="/frontend/master/images/icons/bag--black.svg">
                            </div>
                            <div class="popupContainer__buyNow btn__wrapper">
                              {{ shop.buyButton }}
                            </div>
                          </a>
                        </div>
                      </VueSlickCarousel>
                      <div class="vue-slick-navigation">
                        <button class="prev" :class="sliderAtBegin ? '' : 'disabled'" @click.stop="goToPrevSlide">
                          <i class="arrow left"></i>
                        </button>
                        <button class="next" :class="sliderAtEnd == true ? '' : 'disabled'" @click.stop="goToNextSlide">
                          <i class="arrow right"></i>
                        </button>
                      </div>
                    </div>
                    <div class="popupContainer__item  mobile" v-if="smallScreen && tab.shopsUnavailable == false"
                      v-for="shop in tab.shops" :key="shop.id">
                      <a :href="shop.link" target="_blank" class="popupContainer__item_link"
                        @click.stop="setAnalytics(shop.cartTagging)">
                        <div class="popupContainer__image">
                          <img :src="shop.logo" :alt="shop.buyButton">
                        </div>
                        <div class="popupContainer__bagIcon">
                          <img src="/frontend/master/images/icons/bag--black.svg" :alt="shop.buyButton">
                        </div>
                        <div class="popupContainer__buyNow btn__wrapper">
                          {{ shop.buyButton }}
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="popupContainer__inner popupContainer__inner--empty" id="commerceConnectWrapper" v-else>
                    {{ tab.defaultDescriptionTitle }}
                  </div>
                </div>
              </transition-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
import { truncateRichText } from '@Foundation/directives/truncateRichText/truncateRichText';

export default {
  name: 'routine-finder-commerce-connector',
  components: { VueSlickCarousel },
  directives: {
    truncateRichText
  },
  props: {
    eretailer: {
      type: Object, default: []
    },
    ctaLable: {
      type: String, default: ''
    }
  },
  data() {
    return {
      settings: {
        'dots': true,
        'arrows': true,
        'infinite': false,
        'speed': 500,
        'slidesToShow': 4,
        'slidesToScroll': 4,
        'initialSlide': 0,
        'touchThreshold': 5,
        'responsive': [
          {
            'breakpoint': 1024,
            'settings': {
              'slidesToShow': 4,
              'slidesToScroll': 4,
              'infinite': false,
              'dots': true
            }
          },
          {
            breakpoint: 1023,
            settings: {
              'slidesToShow': 1,
              'slidesToScroll': 1,
              'dots': true,
              'arrows': true,
            }
          },
          {
            breakpoint: 768,
            settings: {
              vertical: true,
              verticalSwiping: true,
              slidesToShow: 3,
              slidesToScroll: 1,
              autoplay: false,
              autoplaySpeed: 0,
              speed: 15000,
              cssEase: 'linear',
              infinite: true,
              arrows: false,
              touchMove: true,
              swipeToSlide: true,
              swipe: true
            }
          },
        ]
      },
      showPopup: false,
      show: 0,
      smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
      currentSlide: 0, // Track the current slide
      totalSlides: 0, // Track total slides
      sliderAtEnd: true,
      sliderAtBegin: false,
    };
  },
  methods: {
    goToPrevSlide() {
      this.$refs.carousel[0].prev(); // Go to the previous slide
      const activeButton = this.$el.querySelector('.slick-dots .slick-active button');
      const activeSlideValue = parseInt(activeButton.textContent.trim());
      if (activeSlideValue === 1) {
        this.sliderAtBegin = false;
        this.sliderAtEnd = true;
      }
    },
    goToNextSlide() {
      this.sliderAtBegin = true;
      this.$refs.carousel[0].next(); // Go to the next slide
      if (this.$el.querySelectorAll('.slick-dots li')) {
        const totalCount = this.$el.querySelectorAll('.slick-dots li').length;
        const activeButton = this.$el.querySelector('.slick-dots .slick-active button');
        const activeSlideValue = parseInt(activeButton.textContent.trim());
        if (parseInt(totalCount) <= activeSlideValue) {
          this.sliderAtEnd = false;
        }
      }
    },
    openRetailer() {
      console.log('Retailer popup opened');
      this.$el.querySelector('#commerceconnectorpopup').classList.remove('showPopup');
    },
    closePopupScreen() {
      this.$el.querySelector('#commerceconnectorpopup').classList.add('showPopup');
    },
    setAnalytics(dataObject) {
      AnalyticsHandler.pushDataLayer(dataObject);
    },

  },

};
</script>

<style lang="scss" src="./routine-finder-commerce-connector.scss"></style>
