<template>
    <div class="routine-finder-popin">
        <div class="multipopin">
            <div class="multipopin__container">
                <modal class="multipopin__container--modal" ref="modal" :config="'rf-spotscan-popin'">
                    <div class="multipopin__container__header">
                        <div class="multipopin__container__title--des"
                            v-truncate-rich-text="{ limit: 35, ellipse: false, singleLine: false }"
                            v-html="getSpotScanData.title">
                        </div>
                        <div class="multipopin__container__imgplaceholder"
                            v-if="getSpotScanData.desktop_image.image_url">
                            <img :src="getSpotScanData.desktop_image.image_url"
                                :alt="getSpotScanData.desktop_image.image_alt" />
                        </div>
                        <div class="multipopin__container__desplaceholder">
                            <div class="multipopin__container__footer--description"
                                v-truncate-rich-text="{ limit: 170, ellipse: false, singleLine: false }"
                                v-if="getSpotScanData.description" v-html="getSpotScanData.description">
                            </div>
                        </div>
                    </div>
                    <div class="multipopin__container__footer">
                        <div class="multipopin__container__footer--cta_placeholder">
                            <div class="multipopin__container__footer--cta_placeholder-one">
                                <a class="cta cta--light" v-if="!smallScreen"
                                    :target="getSpotScanData.spotscan_desktop.spotscan_desktop_target"
                                    :href="getSpotScanData.spotscan_desktop.spotscan_desktop_url"> {{
                                        getSpotScanData.continue_cta_text }} </a>

                                <div v-if="smallScreen">
                                    <script v-if="!isScriptLoaded" src="https://dsf-cdn.loreal.io/dropper.js"
                                        type="application/javascript" @load="onScriptLoad"></script>
                                    <div class="spotscan-embed"></div>
                                    <div class="spotscan-embed" @click="closeRoutineFinder()">
                                        <div class="cta cta--light" @click="closeRoutineFinder()">
                                            <a id="first-cta" @click="closeRoutineFinder()" href="#"
                                                class="btn__wrapper opener button-poppin button-open-dsf-app">{{
                                                    getSpotScanData.continue_cta_text }} </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="multipopin__container__footer--cta_placeholder-two">
                                <button class="cta cta--light" @click="continuenext()">{{
                                    getSpotScanData.not_continue_cta_text }} </button>
                            </div>

                        </div>
                    </div>
                </modal>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '@Feature/Modal/code/Scripts/components/modal';
import { truncateRichText } from '@Foundation/directives/truncateRichText/truncateRichText';

export default {
    name: 'routine-finder-popin-spotscan',
    components: { Modal },
    directives: { truncateRichText },
    props: {
        spotscan: {
            type: Boolean
        }
    },
    data() {
        return {
            smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
            spotscanApplication: 'spotscan',
            spotscanStatus: this.spotscan,
            isScriptLoaded: false
        };
    },
    computed: {
        ...mapGetters(['getCurrentIndex', 'getButtonSetting', 'getSpotScanData', 'getQuestionList']),
    },
    mounted() {
        if (this.spotscanStatus) {
            this.$children[0].$data.showModal = true;
        }
        this.checkIfScriptLoaded();
        this.removeExistingDsfApp();
        this.insertNewDsfApp();
    },

    methods: {
        closeRoutineFinder() {
            document.querySelector(".routine_finder__modal").classList.remove('animationend');
            setTimeout(() => {
                this.$children[0].$data.showModal = false;
                window.eventBus.$emit('routineFinderClose:on', false);
            }, 100);
        },
        continuenext() {
            if (this.getCurrentIndex < this.getQuestionList.length - 1) {
                this.$store.commit('removeAnswerAtIndex', this.getCurrentIndex);
                this.$store.commit('setCurrentIndex', 'next');
                setTimeout(() => {
                    this.$children[0].$data.showModal = false;
                    window.eventBus.$emit('routineFinderClosePopin', false);
                }, 100);
            }
        },
        dsfTagging(service = '', category = '', cta = '') {
            return {
                event: "uaevent",
                ecommerce: "undefined",
                event_name: "site_load_service",
                eventCategory: " ",
                eventAction: " ",
                eventLabel: " ",
                service_name: `${service}`,
                service_category: `${category}`,
                cta_name: `${cta.toLowerCase()}`
            };
        },
        removeExistingDsfApp() {
            document.querySelectorAll('dsf-app').forEach(element => element.remove());
        },

        checkIfScriptLoaded() {
            const script = document.querySelector('script[src="https://dsf-cdn.loreal.io/dropper.js"]');
            if (script && script.readyState === 'complete') {
                this.onScriptLoad();
            } else if (!script) {
                this.isScriptLoaded = false;
            }
        },
        onScriptLoad() {
            this.isScriptLoaded = true;
            this.insertNewDsfApp();
        },

        insertNewDsfApp() {
            if (!this.isScriptLoaded) return;

            const container = this.$el.querySelector('.spotscan-embed');
            if (container) {
                const dsfApp = document.createElement('dsf-app');
                dsfApp.setAttribute('tenant', this.getSpotScanData.spotscan_service.Tenant);
                dsfApp.setAttribute('application', this.getSpotScanData.application);
                dsfApp.setAttribute('customer', this.getSpotScanData.spotscan_service.Customer);
                dsfApp.setAttribute('country', this.getSpotScanData.spotscan_service.Country);
                dsfApp.setAttribute('environment', this.getSpotScanData.spotscan_service.Environment);
                dsfApp.setAttribute('touchpoint', this.getSpotScanData.spotscan_service.Touchpoint);
                dsfApp.setAttribute('type', this.getSpotScanData.spotscan_service.Type);
                dsfApp.setAttribute('switch_ecom', this.getSpotScanData.spotscan_service.Switchecom);
                dsfApp.setAttribute('loadingbg',  this.getSpotScanData.spotscan_service.Loadingbackgroundcolor);
                dsfApp.setAttribute('loadingmsg', this.getSpotScanData.spotscan_service.Loadingmessage);
                dsfApp.setAttribute('csrf_token', '$WEBSITE_TOKEN');
                dsfApp.setAttribute('switch_autostart', this.getSpotScanData.spotscan_service.Switchautostart);
                // container.appendChild(dsfApp);
                document.body.appendChild(dsfApp);
                console.log('dsf-app appended successfully');

            } else {
                console.error('Failed to find .spotscan-embed container');
            }
        },
    }
};
</script>

<style lang="scss" src="./routine-finder-popin-spotscan.scss"></style>
