<template>
  <div>
    <div class="modal__open-btn" @click.prevent="openModal" ref="openModal">
      <slot name="button" :ally="showModal"/>
    </div>

    <div class="modal"
         v-show="showModal"
         :aria-labelledby="ariaLabelledby"
         :aria-describedby="ariaDescribedby">

      <div class="modal__backface" v-if="smallScreen" />
      <div class="modal__backface" v-if="!smallScreen" @click="(config === 'rf-spotscan-popin' || config === 'rf-final-question') ? null : closeModal()" />

      <div class="modal__wrapper" :class="showModal ? '--opened' : ''" ref="wrapper">
        <button class="modal__close-btn-svg"
                @click="closeModal"
                @keyup.esc="closeModal"
                type="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.1514 12L3.57569 19.5757L4.42422 20.4242L11.9999 12.8485L19.5757 20.4242L20.4242 19.5757L12.8485 12L20.4242 4.42421L19.5757 3.57568L11.9999 11.1514L4.42421 3.57569L3.57568 4.42422L11.1514 12Z" fill="black"/>
</svg>
        </button>

        <div class="modal__content">
          <slot/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
  export default {
    name: 'modal',
    props: {
      closeAriaLabel: {
        type: String,
        required: false,
        default: undefined
      },
      closeLabel: {
        type: String,
        required: false,
        default: '&times;'
      },
      ariaLabelledby: {
        type: String,
        required: false,
        default: undefined
      },
      ariaDescribedby: {
        type: String,
        required: false,
        default: undefined
      },
      config:{
        type:String, default:undefined
      }
    },
    data() {
      return {
        showModal: false,
        deferredPrompt: null,
        smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
      };
    },
    computed:{
      ...mapGetters(['getCurrentIndex','getQuestionList'])

    },
    methods: {
      openModal() {
        this.showModal = true;

        this.$nextTick(() => {
          this.setFocus();
        });
      },
      closeModal() {
        if(this.config === 'rf-exit-popin'){
          window.eventBus.$emit('rf-close-exit-popin', false);
         } else if( this.config==='rf-spotscan-popin'){          
          window.eventBus.$emit('rf-close-spotscan-popin', false);
          if (this.getCurrentIndex < this.getQuestionList.length - 1) {
            this.$store.commit('removeAnswerAtIndex', this.getCurrentIndex);
            this.$store.commit('setCurrentIndex', 'next');
          }

        }else{
          
          let medicalOpen = document.querySelectorAll('.medicalpopin').length;
          this.showModal = medicalOpen > 0 ? true : false;
          window.eventBus.$emit('multipopin-close', true);
          this.$refs.openModal.querySelector('button').focus();
        }
        
      },
      setFocus() {
        this.$el.querySelector('.modal__close-btn').focus();
      }
    }
  };
</script>

<style lang="scss" src="./modal.scss"></style>
