<template>
  <div>
    <routine-finder-navi-step></routine-finder-navi-step>
    <div class="routine-finder__qa__container" :key="getCurrentIndex">
      <div class="routine-finder__qa__innerWrapper">
        <h2 class="heading-h2 routineFinder-h2">
          <span>{{ getCurrentIndex + 1 }}.</span>
          <span class="stageTwo_question" v-html="questions.question"></span>
        </h2>
        <routine-finder-why-do-we-ask :initial-data="questions.question_information"
          :question-number="getCurrentIndex"></routine-finder-why-do-we-ask>
        <div class="routine-finder__options__title">
          {{ getButtonSetting.choose_one }}
        </div>
        <div :class="{ '--threeGridColoum': getCurrentIndex != 2 }" class="routine-finder__options">
          <div class="routine-finder__options_grid " v-for="(option, index) in questions.answers" :key="index"
            :class="{ '--disabled': fadeSelectionMap[option.answer_id] }">
            <label :for="option.answer_id" class="routine-finder__options__label"
              :class="{ '--checked': isSelected(getCurrentIndex, option.answer_id) }">
              <input class="routine-finder__options__input" type="radio"
                @click="selectAnswer(getCurrentIndex, questions.category, option.option, option.answer_text, option.answer_id, option.fade_answers, option.try_spotscan, option.result_page_accordions)"
                :name="`question-${getCurrentIndex}`" :checked="isSelected(getCurrentIndex, option.answer_id)"
                :id="option.answer_id" />
              <div class="routine-finder__options__control">
                <img class="routine-finder__options__thumbnail" :src="option.image.image_url" alt="" />
                <div class="routine-finder__options__text"><span>
                    <h3 class="heading-h3" v-if="option.answer_text"
                      v-truncate-rich-text="{ limit: 40, ellipse: false, singleLine: false }"
                      v-html="option.answer_text"> </h3>
                  </span></div>
              </div>
            </label>
          </div>
        </div>
        <div class="routine-finder__qa__buttons">
          <button class="routine-finder__qa__buttons__notSureCta" :class="questions.not_sure ? '' : '--inactive'"
            v-truncate-rich-text="{ limit: 10, ellipse: false, singleLine: true }"
            @click="initNoteSureModal()">{{ getButtonSetting.not_sure }}</button>
          <button :class="['routine-finder__qa__buttons__continueCta', isAnswerSelected ? '' : '--disabled']"
            :disabled="!isAnswerSelected" @click="nextQuestion()"
            v-truncate-rich-text="{ limit: 10, ellipse: false, singleLine: true }">{{ getButtonSetting.continue
            }}</button>
        </div>
      </div>
      <routine-finder-info-modal v-if="routineFinderInfoModal && questions.not_sure"
        :modal-data="questions.not_sure"></routine-finder-info-modal>
      <routine-finder-popin-spotscan v-if="showSpotscan" :spotscan="showSpotscan"></routine-finder-popin-spotscan>
      <routine-finder-final-question v-if="showFinalQuestion"></routine-finder-final-question>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import routineFinderNaviStep from '@Feature/RoutineFinder/code/Scripts/components/routine-finder-navi-steps/routine-finder-navi-step.vue';
import routineFinderWhyDoWeAsk from '@Feature/RoutineFinder/code/Scripts/components/routine-finder-why-do-we-ask/routine-finder-why-do-we-ask.vue';
import { truncateRichText } from '@Foundation/directives/truncateRichText/truncateRichText';
import routineFinderInfoModal from '@Feature/RoutineFinder/code/Scripts/components/routine-finder-modal/routine-finder-info-modal.vue';
import routineFinderPopinSpotscan from '@Feature/RoutineFinder/code/Scripts/components/routine-finder-popin/routine-finder-popin-spotscan.vue';
import routineFinderFinalQuestion from '@Feature/RoutineFinder/code/Scripts/components/routine-finder-final-question/routine-finder-final-question.vue';
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
export default {
  name: 'routine-finder-question-answer',
  components: { routineFinderNaviStep, routineFinderWhyDoWeAsk, routineFinderInfoModal, routineFinderPopinSpotscan, routineFinderFinalQuestion },
  directives: {
    truncateRichText
  },
  data() {
    return {
      fadeAns: [],
      fadeSelectionMap: {},
      routineFinderInfoModal: false,
      trySpotscan: false,
      showSpotscan: false,
      showFinalQuestion: false,
    }
  },
  computed: {
    ...mapGetters(['getQuestionCount', 'getQuestionList', 'getAnswerDataList', 'getAnswerIds', 'getCurrentIndex', 'getButtonSetting']),
    questions() {
      return this.getQuestionList[this.getCurrentIndex];
    },
    isAnswerSelected() {
      const selectedAnswer = this.getAnswerDataList.find(
        (answer) => answer.index === this.getCurrentIndex
      );
      return selectedAnswer !== undefined && selectedAnswer.answerID !== undefined;
    },
    currentQuestionFadeValues() {
      // Retrieves the fade values for the current question
      const currentFade = this.fadeAns.find((fade) => fade.questionNumber === this.getCurrentIndex + 1);
      return currentFade ? currentFade.fadeValues : [];
    }
  },
  watch: {
    getCurrentIndex() {
      // Update fadeSelectionMap with the fades specific to the current question
      this.applyFadesForCurrentQuestion();
    }
  },
  mounted() {
    window.eventBus.$on('routineFinderInfoModalClose:on', (value) => {
      this.routineFinderInfoModal = false;
    });
    window.eventBus.$on('routineFinderClosePopin', (value) => {
      this.showSpotscan = false;
    });
    window.eventBus.$on('rf-close-spotscan-popin', (value) => {
      this.showSpotscan = false;
    });
    window.eventBus.$on('rf-final-question', (value) => {
      this.showFinalQuestion = false;
    });
  },
  methods: {
    selectAnswer(index, question, answerID, answerValue, answer_id, fade_answers, spotscan, accordion) {
      this.clearCurrentQuestionFades();
      this.updateFadeSelectionMap(fade_answers);
      // Update answer data in the store
      if (spotscan) {
        this.trySpotscan = true;
      } else {
        this.trySpotscan = false;

      }
      let questionImportColumn = this.questions.import_column
      this.$store.commit('setAnswerIdsAtIndex', [index, answerID, answer_id, questionImportColumn]);
      const existingAnswer = this.getAnswerDataList.find((answer) => answer.index === index);
      if (existingAnswer) {
        existingAnswer.answerID = answerID;
        existingAnswer.answerValue = answerValue;
        existingAnswer.answer_id = answer_id;
        existingAnswer.result_page_accordions = accordion;
        existingAnswer.spotscan = spotscan;
      } else {
        this.$store.commit('setAnswerDataList', { index, question, answerID, answerValue, answer_id, accordion, spotscan });
      }
    },
    isSelected(index, answer_id) {
      const answer = this.getAnswerDataList.find(
        (answer) => answer.index === index && answer.answer_id === answer_id
      );
      return !!answer;
    },
    nextQuestion() {

      let ctaName = this.getButtonSetting.continue;
      this.tagging(ctaName);
      if (this.getCurrentIndex < this.getQuestionList.length - 1) {
        const existingAnswer = this.getAnswerDataList.find((answer) => answer.index === this.getCurrentIndex);        
        let currentSpotscan;
        if (existingAnswer) {
          currentSpotscan = existingAnswer.spotscan;
        }
        this.showFinalQuestion = false;
        if (this.trySpotscan === true || currentSpotscan === true) {
          this.showSpotscan = true;
        } else {
          this.$store.commit('removeAnswerAtIndex', this.getCurrentIndex);
          this.$store.commit('setCurrentIndex', 'next');
          this.showSpotscan = false;

        }
      }
      else {
        this.showFinalQuestion = true;
      }
      this.scrollToTop();
    },

    clearCurrentQuestionFades() {
      // Clear fades only for the current question's answers in fadeSelectionMap
      const currentFades = this.currentQuestionFadeValues;
      currentFades.forEach((answerId) => {
        this.$delete(this.fadeSelectionMap, answerId);
      });
    },

    updateFadeSelectionMap(fade_answers) {
      // Update fadeSelectionMap and store fades for the current question
      if (Array.isArray(fade_answers)) {
        fade_answers.forEach((answerId) => {
          this.$set(this.fadeSelectionMap, answerId, true);
        });
      }
      this.storeFadeAnswersForCurrentQuestion(fade_answers);
    },

    storeFadeAnswersForCurrentQuestion(fade_answers) {
      // Update fadeAns with the new fades for the current question
      const questionNumber = this.getCurrentIndex + 1;
      const existingFade = this.fadeAns.find((answer) => answer.questionNumber === questionNumber);

      if (existingFade) {
        existingFade.fadeValues = fade_answers;
      } else {
        this.fadeAns.push({ questionNumber, fadeValues: fade_answers });
      }
    },

    applyFadesForCurrentQuestion() {
      // Clear and reapply the fade map for the current question
      this.clearCurrentQuestionFades();
      const currentFades = this.currentQuestionFadeValues;
      currentFades.forEach((answerId) => {
        this.$set(this.fadeSelectionMap, answerId, true);
      });
    },
    scrollToTop() {
      let scrollContainer = document.querySelector('.routine_finder__modal__innerWrapper')
      if (scrollContainer) {
        scrollContainer.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
    },
    initNoteSureModal() {
      this.routineFinderInfoModal = true;
      let ctaName = this.getButtonSetting.not_sure;
      this.tagging(ctaName);
    },
    tagging(cta_name) {
      const existingAnswer = this.getAnswerDataList.find((answer) => answer.index === this.getCurrentIndex);
      let currentSelectedAnser = existingAnswer ? existingAnswer.answerValue.toLowerCase() : 'none';
      let taggingData =this.questions.question_cta_tagging;      
      taggingData.eventAction = cta_name.toLowerCase();
      taggingData.cta_name = cta_name.toLowerCase();
      taggingData.event_detail = currentSelectedAnser;
      AnalyticsHandler.pushDataLayer(taggingData );
    }
  }
};
</script>
<style lang="scss" src="./routine-finder-question-answer.scss"></style>
