<template>
  <div>
    <routine-finder-loader-screen v-if="resultLoaderScreenPage"></routine-finder-loader-screen>
  <div v-if="resultpageopen" class="routine-finder-result-page">
    <routine-finder-result-by-email-form :email-product-ean="productEanlist" :datas="emailFormData"></routine-finder-result-by-email-form>
    <div class="routine-finder-result__heading container--is-maxwidth">
      <h2 class="routineFinder-h2" v-truncate-rich-text="{ limit: 40, ellipse: false, singleLine: true }"
        v-html="this.apiData.result_title_section?.title"></h2>
      <div v-truncate-rich-text="{ limit: 135, ellipse: false, singleLine: false }" class="routine-finder-result__desc"
        v-html="this.apiData.result_title_section?.description"></div>
    </div>
    <!--your Routine-->
    <div class="routine-finder-result__your-routine container--is-maxwidth">
      <h3 class="heading-h3" v-truncate-rich-text="{ limit: 25, ellipse: false, singleLine: true }">
        {{ ctaContent.your_routine }}</h3>
      <routine-finder-your-product-result :result-product-data="productData"></routine-finder-your-product-result>
    </div>
    <!--your Routine-->
    <routine-finder-your-profile></routine-finder-your-profile>
    <div class="routine-finder-result__startExit-Container">
      <button class="startOverFinder cta cta--light" @click="startOverApp()">
        {{ ctaContent.start_over_finder }}
      </button><button class="ExitOverFinder cta cta--light" @click="ExitApp()">
        {{ ctaContent.exit }}
      </button>
    </div>
  </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { truncateRichText } from '@Foundation/directives/truncateRichText/truncateRichText';
import routineFinderResultByEmailForm from '@Feature/RoutineFinder/code/Scripts/components/routine-finder-result-page/routine-finder-result-by-email-form.vue';
import routineFinderYourProductResult from '@Feature/RoutineFinder/code/Scripts/components/routine-finder-result-page/routine-finder-your-product-result.vue';
import routineFinderYourProfile from '@Feature/RoutineFinder/code/Scripts/components/routine-finder-result-page/routine-finder-your-profile.vue';
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
import routineFinderLoaderScreen from '@Feature/RoutineFinder/code/Scripts/components/routine-finder-loader/routine-finder-loader-screen.vue';

export default {
  name: 'routine-finder-result-page',
  components: { routineFinderResultByEmailForm, routineFinderYourProfile, routineFinderYourProductResult, routineFinderLoaderScreen },
  directives: {
    truncateRichText
  },
  data() {
    return {
      emailFormData: '',
      productData: [],
      ctaContent: [],
      apiData: [],
      resultLoaderScreenPage: true,
      resultpageopen: false,
      productEanlist: '',
    }
  },
  computed: {
    ...mapGetters(['getAnswerIds']),
  },
  created() {
    this.fetchData();
  },
  mounted() {
    window.eventBus.$on('routineFinderresultpage:on', (value) => {
      this.resultLoaderScreenPage = false ;
      this.resultpageopen = value;
    });
    this.gaTagging(); 
  },
  methods: {
    gaTagging() {
      AnalyticsHandler.pushDataLayer({
        'event': 'updatevirtualpath',
        'virtualPageURL': '/routine-finder/result page',
        'virtualPageTitle': '/routine finder result page',
      });
    },
    startOverApp() {
      window.eventBus.$emit('startOverApp:on', true)
    },
    ExitApp() {
      const animationClass = document.querySelector(".routine_finder__modal")
      if (animationClass) {
        animationClass.classList.remove('animationend');
      }
      this.$store.commit('resetQuestionAnsDatas');
      setTimeout(() => {
        window.eventBus.$emit('routineFinderClose:on', false);
      }, 100);
    },
    async fetchData() {
      const apiURL = '/api/routinefinder/RoutineFinderApiBus';
      const updatedData = this.getAnswerIds.map(row => [row[3], row[1]]);
      const jsonData = {
        "Stage": 4,
        "Parameters": {
          "search": updatedData
        }
      };
      try {
        const response = await fetch(apiURL, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json;charset=utf-8' },
          body: JSON.stringify(jsonData),
        });
        if (response.ok) {
          const data = await response.json(); // Convert response to JSON  
          this.apiData = data.data;
          this.emailFormData = this.apiData.email_service_data;
          this.productData = this.apiData.product_results.routineresponselist;
          this.ctaContent = this.apiData.result_page_cta;
          this.productEanlist = this.apiData.product_results.productEanList
        }
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    }
  }
};
</script>
<style lang="scss" src="./routine-finder-result-page.scss"></style>