<template>
  <div sxa-component="">
    <div class="component-content">
      <div class="container--is-fullwidth">
        <div class="container container--is-maxwidth">
          <div class="ingredients-list-container">
            <div class="ingredient-header" v-html="title"></div>
            
            <!-- Desktop template section rendering -->
            <template v-if="!smallScreen">
              <ul class="ingredients-list">
                <li
                  class="ingredients-list__item"
                  v-for="(item, index) in keyIngredientsArray"
                  :key="index"
                >
                  <div class="ingredients-card">
                    <div class="ingredients-card__content">
                      <div class="ingredients-card__img-wrapper"  :class="imageGradientEnable && item.Keyimage && !item.KeyVideo? 'gradientEnabled':''"> 
                        <div class="ingredients-card__caption" v-html="item.Title"></div>
                        <img v-if="item.Keyimage && !item.KeyVideo"
                          :src="item.Keyimage"
                          alt="Anthelios uv mune 400"
                          class="ingredients-card__img"
                        /> 
                        <video v-lazy-load playsinline v-if="item.KeyVideo && !item.Keyimage" :data-src="item.KeyVideo" autoplay loop muted ></video>
                      </div>
                      <div class="ingredients-card__description" v-html="item.Description"></div>
                    </div>
                  </div>
                  
                </li>
              </ul>
            </template>

            <!-- Mobile template section rendering -->
            <div class="ingredients-list mobile" v-else>
              <swiper :options="swiperOptions" @slideChange="slideChangeEvent" class="swiper" ref="swiper" >
                <swiper-slide
                  class="ingredients-list__item"
                  v-for="(item, index) in keyIngredientsArray"
                  :key="index"
                >
                  <div class="ingredients-card" :class="{'re-animate': !isVisible && index === 0 }">
                    <div class="ingredients-card__content">
                      <div class="ingredients-card__img-wrapper" :class="imageGradientEnable && item.Keyimage && !item.KeyVideo? 'gradientEnabled':''">
                        <div class="ingredients-card__caption"  v-html="item.Title"></div>
                        <img v-if="item.Keyimage && !item.KeyVideo"
                          :src="item.Keyimage"
                          alt="Anthelios uv mune 400"
                          class="ingredients-card__img"
                        /> 
                        <video v-lazy-load playsinline v-if="item.KeyVideo && !item.Keyimage" :data-src="item.KeyVideo" autoplay loop muted ></video> 
                     
                      </div>
                      <div class="ingredients-card__description" v-html="item.Description"></div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div class="carousel-navigation">
              <div class="carousel__arrow carousel__arrow--left" @click="prev()" :class="{ 'disabled': swiperAtBegain }"/>
              <div class="swiper-pagination"></div>
              <div class="carousel__arrow carousel__arrow--right" @click="next()" :class="{ 'disabled': swiperAtEnd }"/>
            </div>
            <div class="validity-text-container">
              <div class="validitiy-text">{{ validityText }}</div>
            </div>
            <div class="dropdown-div">
              <collapse class="transcript__collapse" :aria-label="'show transcript'" v-if="otherIngredient">
                <template slot="title" slot-scope="scoped">
                  <template v-if="scoped.collapsed">
                    <div class="showdiv">
                      <span class="show-transcript" v-html="showText"/>
                      <span v-show="showText" class="arrow down"/>
                    </div>
                  </template>
                  <template v-else>
                    <div class="hidediv">
                      <span class="hide-transcript" v-html="hideText"/>
                      <span v-show="hideText" class="arrow up"/>
                    </div>
                  </template>
                </template>
                <button class="no-js collapse-title-btn" aria-label=""/>
                <div v-html="otherIngredient" class="transrcript-class ingredient__list-all" data-ioplist=""/>
                <br >
                <p class="ingredient__filcode">{{ ingredientFilcode }}</p>
            </collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { lazyLoad } from '@Feature/HubVideo/code/Scripts/directives/videoLazyload.js';
import Collapse from '../../../../Collapse/code/Scripts/components/collapse.vue';
import { Swiper as SwiperClass, Pagination, Navigation } from 'swiper/js/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';

SwiperClass.use([Pagination, Navigation]);
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);

export default {
  name: 'key-ingredients-v2',
  directives: {
    lazyLoad
  },
  props: {
    keyIngredientsArray: {
      type: [Array, Object],
      default: '',
    },
    imageGradientEnable:{
      type: {Boolean,String },
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '#009FE3',
    },
    lineColor: {
      type: String,
      default: '#fff',
    },
    packshotImageDesktop: {
      type: String,
      default: '',
    },
    packshotImageMobile: {
      type: String,
      default: '',
    },
    ingredientFilcode: {
      type: String,
      default: '',
    },
    showText: {
      type: String,
      default: 'See full list of ingredients',
    },
    hideText: {
      type: String,
      default: 'hide all ingredients',
    },
    otherIngredient: {
      type: String,
      default: '',
    },
    validityText: {
      type: String,
      default: 'Validity Texts for Ingredients',
    },
    productName: {
      type: String,
      default: '',
    },
    productId: {
      type: String,
      default: '',
    },
    imgAlt: {
      type: String,
      default: 'Technology and filters',
    },
  },

  data() {
    return {
      smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
      isIngredientContent: false,
      swiperAtEnd: false,
      swiperAtBegain: true,
      swiperOptions: {
        loop: false,
        initialSlide: 0,
        speed: 500,
        centeredSlides: true,
        slidesPerView: 1.3,
        spaceBetween: 24,
        navigation: {
          nextEl: '.carousel__arrow carousel__arrow--left',
          prevEl: '.carousel__arrow carousel__arrow--right',
          hiddenClass: '.swiper-button-hidden',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          type: 'bullets',
        },
      },
      indexValue: 0,
      isVisible: false,
      timeoutId: null
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  },
  methods: {
    toggleIngredientContent() {
      this.isIngredientContent = !this.isIngredientContent;
    },

    prev() {
      this.$refs.swiper.$swiper.slidePrev();
      this.swiperAtEnd = this.$refs.swiper.$swiper.isEnd;
      this.swiperAtBegain = this.$refs.swiper.$swiper.isBeginning;
    },

    next() {
      this.$refs.swiper.$swiper.slideNext();
      this.swiperAtBegain = this.$refs.swiper.$swiper.isBeginning;
      this.swiperAtEnd = this.$refs.swiper.$swiper.isEnd;
    },

    slideChangeEvent() {
      this.swiperAtBegain = this.$refs.swiper.$swiper.isBeginning;
      this.swiperAtEnd = this.$refs.swiper.$swiper.isEnd;
      this.indexValue = this.$refs.swiper.$swiper.activeIndex - 1;
      this.$refs.swiper.$swiper.slides[this.indexValue].querySelector('.ingredients-card__img-wrapper').classList.add('no-animation')
      if (this.indexValue + 1 === this.keyIngredientsArray.length - 1) {
        this.$refs.swiper.$swiper.slides[this.indexValue + 1].querySelector('.ingredients-card__img-wrapper').classList.add('no-animation')
      }
    },
    isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top + 400 >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) 
      );
    },
    handleScroll() {
      const element = this.$el.querySelector('.ingredient-header');
      if (this.isElementInViewport(element)) {
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }
        this.timeoutId = setTimeout(() => {
          this.isVisible = true;
        }, 100);
      }
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    Collapse
  },
};
</script>

<style lang="scss" src="./key-ingredients-v2.scss"></style>