<template>
  <div ref="videoPlayer" class="hub-video__container" :class="mobileScreen? 'smallScreen' : 'desktopScreen'">
    <video playsinline loop muted autoplay type="video/mp4" v-lazy-load :data-src="this.currentVideo" >
    </video> 
  </div>
</template>

<script>
import { lazyLoad } from '../directives/videoLazyload';
export default {
  name: 'hub-video',
  directives: {
    lazyLoad
  },
  props: {
    videoSources: {
      type: Object,
      required: true, 
    },
  },
  data() {
    return {
      isOnViewPort: false,
      currentVideo: '',
      mobileScreen: false,
    }
  }, 
  mounted() {
    this.setVideoSource();
    this.playVideo();
  },
 
  methods: {
    playVideo() {
      this.$refs.videoPlayer.querySelector('video').play()
    },
    setVideoSource() {
      if (window.innerWidth <= 768) {
        this.currentVideo = this.videoSources.MobileVideo;
        this.mobileScreen = true;
      } else {
        this.currentVideo = this.videoSources.DesktopVideo;
      }
    },
  }
};
</script>

<style lang="scss" src="./hub-video.scss"></style>
