<template>
    <div class="routine-finder-product-retailer">
        <div class="routine-finder-product-retailer__cta">
            <button v-truncate-rich-text="{ limit: 12, ellipse: false, singleLine: true }"
                @click.stop="openRetailer()" class="buyBtn">{{ ctaLable }}</button>
        </div>

        <div v-if="showPopup" class="lrp-retailer " @click.stop :id="`popupContainerOuterRetailer-${uniqueId}`">
            <div class="popupContainerOuter" id="popupContainerOuter">
                <div class="popupContainer">
                    <div class="popupContainer__close" @click.stop="closePopupScreen()">&#10006;
                    </div>
                    <div class="container" id="app">
                        <div class="popupContainer__inner" id="commerceConnectWrapper">
                            <div class="container--is-maxwidth container--padded-hor" v-if="retailerdata.length > 0">
                                <slider :per-page-custom="[[320, 1], [1024, 4]]" :is-pagination-clickable="true"
                                    :loop="false" :with-focusable-items="true" :scroll-per-page="true" :start-at="0"
                                    :navigation-enabled="true" :pagination-enabled="true" :swipe-on-desktop="false"
                                    :nav-tagging-label="navTaggingLabel" :item-per-page-desktop="4"
                                    :page-category="pageCategory" ref="slider">
                                    <slide v-for="(retailer, index) in retailerdata" :key="index">
                                        <a :href="retailer.retailerLink"                                           
                                            target="_blank" class="card-shop" panel-focusable="">
                                            <div class="card-shop__img-wrapper">
                                                <img :src="retailer.retailerImage"
                                                    :alt="retailer.productRetailerItemName" class="card-shop__img"
                                                    width="200" height="65">
                                            </div>
                                            <div class="btn btn--black btn--buy btn--icon">
                                                <img :src="retailer.ctaIcon" class="btn__icon" aria-hidden="true"
                                                    :alt="retailer.ctaLabel">
                                                <span class="btn__wrapper">
                                                    <span class="btn__text">
                                                        {{ retailer.ctaLabel }}
                                                    </span>
                                                </span>
                                            </div>
                                        </a>
                                    </slide>
                                </slider>
                            </div>
                            <div v-else>
                                <p class="no-product" style="text-align: center;">{{ errorMsg }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Slide from '@Feature/Slider/code/Scripts/components/slide/slide';
import Slider from '@Feature/Slider/code/Scripts/components/slider/slider';

export default {
    name: 'routine-finder-product-retailer',
    components: {
        Slide,
        Slider
    },
    props: {
        retailerdata: {
            type: Object, default: []
        },
        errorMsg: {
            type: String, default: ''
        },
        ctaLable:{
            type: String, default:''
        }
    },
    data() {
        return {
            smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
            showPopup: false, // Controls popup visibility
            uniqueId: Math.random().toString(36).substring(2)
        }
    },
    methods: {
        openRetailer() {
            this.showPopup = true;
            this.$nextTick(() => {
                this.initializeSlider(); // Initialize slider when popup is visible
            });
        },
        closePopupScreen() {
            this.showPopup = false;
        },        
        initializeSlider() {
            const slider = this.$refs.slider;
            if (slider && typeof slider.initialize === 'function') {
                slider.initialize();
            }
        }
    }

};
</script>

<style lang="scss" src="./routine-finder-product-retailer.scss" ></style>