<template>
    <div class="routine-finder-popin routine-finder-popin--exit">
        <div class="multipopin">
            <div class="multipopin__container">
                <modal class="multipopin__container--modal" ref="modal" :config="'rf-exit-popin'">
                    <div class="multipopin__container__header">
                        <div class="multipopin__container__title--des" v-if="questions.exit_popin.title"
                            v-truncate-rich-text="{ limit: 55, ellipse: false, singleLine: false }"
                            v-html="questions.exit_popin.title">
                        </div>
                        <div class="multipopin__container__imgplaceholder">
                            <img :src="questions.exit_popin.image.image_url"
                                :alt="questions.exit_popin.image.image_alt" />
                        </div>
                        <div class="multipopin__container__desplaceholder">
                            <div class="multipopin__container__footer--description"
                                v-truncate-rich-text="{ limit: 170, ellipse: false, singleLine: false }"
                                v-if="questions.exit_popin.description" v-html="questions.exit_popin.description">
                            </div>
                        </div>
                    </div>
                    <div class="multipopin__container__footer">
                        <div class="multipopin__container__footer--cta_placeholder">
                            <div class="multipopin__container__footer--cta_placeholder-one">
                                <button class="cta cta--light" @click="exitPopin()">{{
                                    getButtonSetting.exit_popin.continue }} </button>
                            </div>
                            <div class="multipopin__container__footer--cta_placeholder-two">
                                <button class="cta cta--light" @click="continueButton()">{{
                                    getButtonSetting.exit_popin.exit
                                    }} </button>
                            </div>

                        </div>
                    </div>
                </modal>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Modal from '@Feature/Modal/code/Scripts/components/modal';
import SpotscanEmbed from '@Feature/SpotscanEmbed/code/Scripts/components/spotscan-embed.vue';
import { truncateRichText } from '@Foundation/directives/truncateRichText/truncateRichText';
export default {
    name: 'routine-finder-exit-popin',
    components: { SpotscanEmbed, Modal },
    directives: { truncateRichText },
    data() {
        return {
            smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
            spotscanApplication: 'spotscan',
            spotscanStatus: this.spotscan
        }
    },
    computed: {
        ...mapGetters(['getCurrentIndex', 'getButtonSetting', 'getSpotScanData', 'getQuestionList']),
        questions() {
            return this.getQuestionList[this.getCurrentIndex];
        },
    },
    mounted() {
        this.$children[0].$data.showModal = true;
    },
    methods: {
        exitPopin() {
            this.$children[0].$data.showModal = false;
            setTimeout(() => {
                window.eventBus.$emit('rf-close-exit-popin', false);
            }, 100);
        },
        continueButton() {
            this.$store.commit('resetQuestionAnsDatas');
            document.querySelector(".routine_finder__modal").classList.remove('animationend');
            setTimeout(() => {
                window.eventBus.$emit('routineFinderClose:on', false);
            }, 100);
        },
    }
};
</script>
<style lang="scss" src="./routine-finder-popin-spotscan.scss"></style>
