<template>
  <div class='brand-hub'>
    <vue-scroll-snap :fullscreen="true" ref="brandhubref">
      <div id="brandhubscroll">
        <div v-if="hubVideoDisplay" class="item" id="index00">
          <brand-content-hub-video></brand-content-hub-video>
          <div class="brand_hub_svg_bottom">
            <svg width="2px" height="62px" viewBox="0 0 2 62" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Line</title>
              <g id="DESKTOP-&amp;-MOBILE" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                stroke-dasharray="0,12" stroke-linecap="round" stroke-linejoin="round">
                <g id="BRAND-HUB_V4.3" transform="translate(-719.000000, -2221.000000)" stroke="#FFFFFF"
                  stroke-width="2">
                  <g id="IMG-Copy" transform="translate(0.000000, 2221.000000)">
                    <line x1="720" y1="1.13686838e-13" x2="720" y2="71" id="Line"></line>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div class="item" :key="index" v-for="(brandhub, index) in initialData.BrandHubSectionList"
          :id="'index' + index" :data-index="index" :data-tagging="scrollTagging(brandhub.ScrollEventTagging)">
          <div class="brand_content__hub brand_content__hub__section">
            <div class="brand_content__hub__section__item">
              <div class="brand_content__hub__image "
                :style="bgimage(brandhub.DesktopBackgroundImageSrc, brandhub.MobileBackgroundImageSrc)">
                <img :src="brandhub.DesktopBackgroundImageSrc" class="desktop" :alt="brandhub.DesktopBackgroundImageAlt"
                  v-if="!smallScreen" />
                <img :src="brandhub.MobileBackgroundImageSrc" class="mobile" :alt="brandhub.DesktopBackgroundImageAlt"
                  v-if="smallScreen" />
                <div class="brand_hub_svg_top">
                  <svg width="2px" height="219px" viewBox="0 0 2 219" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="DESKTOP-&amp;-MOBILE" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                      stroke-dasharray="0,12" stroke-linecap="round" stroke-linejoin="round">
                      <g id="BRAND-HUB_V4.3" transform="translate(-719.000000, -870.000000)" stroke="#FFFFFF"
                        stroke-width="2">
                        <g id="IMG" transform="translate(0.000000, 864.000000)">
                          <line x1="720" y1="-5" x2="720" y2="224" id="Line-2-Copy"></line>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="brand_content__hub__text" :class="'index--' + index" data-aos="fade-down"
                  data-aos-duration="1000">
                  <div class="brand_content__hub__text__title" v-html="brandhub.Title"> </div>
                  <div class="brand_content__hub__text__description" v-html="brandhub.Description">
                  </div>
                  <div class="brand_content__hub__text__cta btn"
                    @click="addclass(index), tagging(brandhub.CTAEventTagging)"
                    v-if="brandhub.IsSlider && brandhub.CTALabel != ''">
                    <span class="btn__wrapper" v-html="ltrim(brandhub.CTALabel)">

                    </span>
                  </div>
                  <div class="brand_content__hub__text__cta btn"
                    v-if="brandhub.EnableNewsletter && brandhub.CTALabel != ''">
                    <a :href="brandhub.NewsletterLink" @click="tagging(brandhub.CTAEventTagging)" target="_blank"><span
                        class="btn__wrapper">
                        {{ brandhub.CTALabel }}
                      </span></a>
                  </div>
                </div>

                <div class="brand_hub_svg_bottom">
                  <svg width="2px" height="62px" viewBox="0 0 2 62" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Line</title>
                    <g id="DESKTOP-&amp;-MOBILE" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                      stroke-dasharray="0,12" stroke-linecap="round" stroke-linejoin="round">
                      <g id="BRAND-HUB_V4.3" transform="translate(-719.000000, -2221.000000)" stroke="#FFFFFF"
                        stroke-width="2">
                        <g id="IMG-Copy" transform="translate(0.000000, 2221.000000)">
                          <line x1="720" y1="1.13686838e-13" x2="720" y2="71" id="Line"></line>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>


            <!--slider-->
            <div class="slider-section">
              <div class="--section-one" v-if="brandhub.EnableNewDesign === false">
                <div class="brand_content__hub__slider" :class="brandhub.BrandHubSliderList.length == 1
                  ? 'brand-hub_slider-one'
                  : brandhub.BrandHubSliderList.length == 2
                    ? 'swiper-slider-center'
                    : brandhub.BrandHubSliderList.length == 3
                      ? 'slider-three'
                      : ''" v-if="activeSliders[index]">
                  <div v-if="brandhub.IsSlider">

                    <!-- <div class="brand_slider_close" @click="removeExstingclass(index)"> <span>X</span> </div>     -->
                    <div class="brand_content__hub__text__slider_title" v-html="brandhub.Title" v-if="!smallScreen">
                    </div>
                    <div
                      @pageChange="whenPageChange($event, brandhub.Title, brandhub.BrandHubSliderList, brandhub.CTAEventTagging)"
                      class="swiper-container" :class="`swiper-${dynamicClass}${index}`">
                      <div class="swiper-pagination" slot="pagination" v-if="smallScreen"></div>
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(brandhubcarousel, swiperIndex) in brandhub.BrandHubSliderList"
                          :data-index="swiperIndex" :key="swiperIndex">
                          <a :href="brandhubcarousel.SliderCTALink"
                            @click="gatagging(brandhubcarousel, brandhub.CTAEventTagging)" target="_blank"
                            class="card-shop" panel-focusable="">
                            <div class="brand_content__hub__slider-scroll-section" :class=" brandhubcarousel.SliderCTALabel !='' ?'' : '--no-cta'">                              
                              <div class="card-shop__img-wrapper ">
                                <img :src="brandhubcarousel.SliderDesktopBackgroundImageSrc" class="card-shop__img">
                              </div>
                              <div class="brand_content__hub__slider_title"
                                v-html="titleWrap(brandhubcarousel.SliderTitle)"></div>
                              <div class="brand_content__hub__slider_des" v-html="brandhubcarousel.SliderDescription">
                              </div>
                            </div>
                            <div class="brand_content__hub__slider_btn">
                              <button> {{ brandhubcarousel.SliderCTALabel }}</button>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="carousel__nav">
                        <button class="carousel__arrow carousel__arrow---left "
                          :class="swiperAtBegain === true ? '' : 'disabled'">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M8.42421 3.57573L16.8485 11.9998L8.42422 20.4243L7.57568 19.5757L15.1514 11.9998L7.57569 4.42427L8.42421 3.57573Z"
                              fill="black" />
                          </svg>
                        </button>
                        <div class="brand_slider_close" @click="removeExstingclass(index)"> <span>Close</span> </div>
                        <button class="carousel__arrow carousel__arrow---right " c>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M8.42421 3.57573L16.8485 11.9998L8.42422 20.4243L7.57568 19.5757L15.1514 11.9998L7.57569 4.42427L8.42421 3.57573Z"
                              fill="black" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="--section-two" :class="brandhub.BrandHubSliderList.length == 1
                ? 'brand-hub_slider-one'
                : brandhub.BrandHubSliderList.length == 2
                  ? 'swiper-slider-center'
                  : brandhub.BrandHubSliderList.length == 3
                    ? 'slider-three'
                    : ''" v-if="brandhub.EnableNewDesign === true">
                <div class="brand_content__hub__slider" v-if="activeSliders[index]">
                  <div v-if="brandhub.IsSlider">
                    <!-- <div class="brand_slider_close" @click="removeExstingclass(index)"> <span>X</span> </div>     -->
                    <div class="brand_content__hub__text__slider_title" v-html="brandhub.Title" v-if="!smallScreen">
                    </div>
                    <div
                      @pageChange="whenPageChange($event, brandhub.Title, brandhub.BrandHubSliderList, brandhub.CTAEventTagging)"
                      class="swiper-container" :class="`swiper-${dynamicClass}${index}`">
                      <div class="swiper-pagination" slot="pagination" v-if="smallScreen"></div>
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(brandhubcarousel, swiperIndex) in brandhub.BrandHubSliderList"
                          :data-index="swiperIndex" :key="swiperIndex">
                          <a :href="brandhubcarousel.SliderCTALink" class="card-shop"
                            @click="gatagging(brandhubcarousel, brandhub.CTAEventTagging)" target="_blank"
                            panel-focusable="">
                            <div class="brand_content__hub__slider--section">
                              <div class="card-shop__img-wrapper"
                                v-if="brandhubcarousel.SliderDesktopBackgroundImageSrc">
                                <img :src="brandhubcarousel.SliderDesktopBackgroundImageSrc">
                              </div>
                              <div class="brand_content__hub__slider_content"
                                :class="brandhubcarousel.SliderDesktopBackgroundImageSrc ? '' : '--brand_content__hub-no-image'">
                                <div class="brand_content__hub__slider_title"
                                  v-html="titleWrap(brandhubcarousel.SliderTitle)">
                                </div>

                                <div class="brand_content__hub__slider_btn">
                                  <button>{{ brandhubcarousel.SliderCTALabel }}</button>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div class="carousel__nav">
                        <button class="carousel__arrow carousel__arrow---left"
                          :class="swiperAtBegain === true ? '' : 'disabled'">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M8.42421 3.57573L16.8485 11.9998L8.42422 20.4243L7.57568 19.5757L15.1514 11.9998L7.57569 4.42427L8.42421 3.57573Z"
                              fill="black" />
                          </svg>
                        </button>
                        <div class="brand_slider_close" @click="removeExstingclass(index)"> <span>Close</span> </div>
                        <button class="carousel__arrow carousel__arrow---right"
                          :class="swiperAtEnd === true ? '' : 'disabled'">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M8.42421 3.57573L16.8485 11.9998L8.42422 20.4243L7.57568 19.5757L15.1514 11.9998L7.57569 4.42427L8.42421 3.57573Z"
                              fill="black" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- slider close-->



          </div>
        </div>
      </div>
    </vue-scroll-snap>
  </div>
</template>
<script>

import VueScrollSnap from "@Feature/VueScrollSnap/code/Scripts/components/vue-scroll-snap.vue";
import brandContentHubVideo from "@Feature/BrandContentHub/code/Scripts/components/brand-content-hub-video.vue";
import { tag, AnalyticsHandler } from '@Foundation/analyticsHandler';
import Swiper from 'swiper';
import AOS from 'aos';


export default {
  name: 'brand-content-hub',
  components: { VueScrollSnap, Swiper, AOS, brandContentHubVideo },
  directives: { tag },
  props: {
    initialData: { type: [Object, Array], required: false },
    a11yNextPanelText: { type: String, required: false, default: 'Next panel' },
    a11yPreviousPanelText: { type: String, required: false, default: 'Previous panel' },
  },
  data() {
    return {
      smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
      currentElementId: "",
      isTrigger: true,
      indexArray: [],
      scrollUp: false,
      activeSliders: {},
      swiperVisible: false,
      swiperInstance: null,
      hubVideoDisplay: false,
      swiperAtEnd: true,
      swiperAtBegain: false,
      dynamicClass: new Date().getMilliseconds().toString(36),
      swiperOptions: {
        loop: false,
        initialSlide: 0,
        speed: 500,
        slidesPerView: 3,
        spaceBetween: 32,
        roundLengths: true,
        variableWidth: true,
        resizeObserver: false,
        slidesPerGroup: 3,
        centeredSlides: false,
        startX: '',
        navigation: {
          nextEl: '.carousel__arrow---right',
          prevEl: '.carousel__arrow---left',

        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          type: 'bullets',
        },
        breakpoints: {
          1024:{
            slidesPerView: 3.25,
            spaceBetween: 32,
            slidesPerGroup: 3,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0,
            slidesPerGroup: 1,
          },
          360: {
            centeredSlides: true,
            slidesPerView: 1,
            spaceBetween: 0,
            slidesPerGroup: 1,
          },
          320: {
            centeredSlides: true,
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 0,
          },
        },
      }
    };
  },
  created() {
    AOS.init();
  },
  mounted() {
    this.data = this.initialData.BrandHubSectionList;
    window.dataLayer.push({ ...this.data[0].ScrollEventTagging });
    document.querySelector('.breadcrumb')?.classList.add('bredcrumb-hide');
    var lastScrollTop1 = 0;
    window.addEventListener('touchstart', (event) => {
      const videoHubElement = document.querySelector('.hub-video__container');
      const myElement = videoHubElement ? document.getElementById('index00') : document.getElementById('index0');
      var bounding = myElement.getBoundingClientRect();


      var st1 = window.pageYOffset || document.documentElement.scrollTop;
      if (st1 > lastScrollTop1) {
        document.querySelector('.main-menu').classList.remove('main-menu-hide');
      }
      else {
        document.querySelector('.main-menu').classList.add('main-menu-hide');
      }
      if (bounding.top <= 10 && bounding.top >= 0) { document.querySelector('.main-menu').classList.remove('main-menu-hide'); }

      lastScrollTop1 = st1 <= 0 ? 0 : st1;
    }, false);
    var lastScrollTop = 0;
    window.addEventListener('wheel', event => {
      const videoHubElement = document.querySelector('.hub-video__container');
      const myElement = videoHubElement ? document.getElementById('index00') : document.getElementById('index0');
      var bounding = myElement.getBoundingClientRect();

      if (bounding.top <= 10 && bounding.top >= 0) { document.querySelector('.main-menu')?.classList.remove('main-menu-hide'); }


      var st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop) {

        document.querySelector('.main-menu')?.classList.remove('main-menu-hide');

      } else {

        document.querySelector('.main-menu')?.classList.add('main-menu-hide');

      }
      if (bounding.top <= 10 && bounding.top >= 0) { document.querySelector('.main-menu')?.classList.remove('main-menu-hide'); }
      lastScrollTop = st <= 0 ? 0 : st;
      //this.scrollUp = event?.wheelDelta > 0 || event?.deltaY < 0
    }, false);
    this.$refs.brandhubref.$el.addEventListener('scroll', this.handleScroll);
    this.videoLoaded();
  },
  methods: {
    whenPageChange(currentPage, sliderTitle, data, ctatagging) {
      let slidenumber = currentPage + 1;
      let title = [];
      let maintitle = "none";
      let slidetitle = "none";
      if (this.smallScreen) {
        title = data[currentPage];
      } else {
        if (slidenumber == 1) {
          title = data[0]
        } else {
          let index = (slidenumber * 3) - 2;
          title = data[index - 1];
        }
      }
      if (this.decodeHtmlstring(sliderTitle) != '') {
        maintitle = this.decodeHtmlstring(sliderTitle).toLowerCase();
        maintitle.replace(/\s+/g, ' ').trim();
      }
      slidetitle = this.decodeHtmlstring(title?.SliderTitle).toLowerCase();
      AnalyticsHandler.pushDataLayer({
        'event': 'uaevent',
        'ecommerce': 'undefined',
        'event_name': 'slider_button_click',
        'eventCategory': ctatagging.eventCategory.toLowerCase(),
        'eventAction': 'select::slider navigation elements',
        'eventLabel': `${maintitle}::${slidetitle}::${slidenumber}`,
        'cta_name': `${maintitle}::${slidetitle}::${slidenumber}`,
        'module_name': `slider navigate::${maintitle}`,
      });
    },
    decodeHtmlstring(html) {
      let div = document.createElement("div");
      div.innerHTML = html;
      let datas = div.innerText.replaceAll('\n', ' ');
      return datas;
    },
    gatagging(data, ctatagging) {
      let obj = {
        "event": "uaevent",
        "ecommerce": "undefined",
        "eventCategory": ctatagging.eventCategory.toLowerCase(),
        "eventAction": "select::brand content hub",
        "eventLabel": data.SliderCTALabel.toLowerCase() + "|" + data.SliderCTALink
      };
      window.dataLayer.push(obj);
    },
    scrollTagging(tagging) {
      return JSON.stringify(tagging);

    },
    ltrim(str) {
      if (!str) return str;
      return str.replace(/^\s+/g, '');
    },
    decodeEntity(inputStr) {
      var textarea = document.createElement("textarea");
      textarea.innerHTML = inputStr;
      return textarea.value;
    },
    getlength(param) {
      if (!this.smallScreen) {
        if (param) {
          if (param.length == 2) {
            return 'slider-two';
          }

        }
      }

    },
    titleWrap(title) {
      const limit = 50;
      if (title.length > limit) {
        return title.substring(0, limit) + '';
      } else {
        return title;
      }

    },
    descriptionWrap(title) {
      var decodedvalue = this.decodeEntity(title);
      const limit = 148;
      if (title.length > limit) {
        return decodedvalue.substring(0, limit) + '...';
      } else {
        return decodedvalue;
      }

    },
    handleScroll() {
      AOS.init();

      const videoHubElement = document.querySelector('.hub-video__container');
      const myElement = videoHubElement ? document.getElementById('index00') : document.getElementById('index0');
      var bounding = myElement.getBoundingClientRect();

      if (bounding.top <= 10 && bounding.top >= 0) {
        document.querySelector('.main-menu')?.classList.remove('main-menu-hide');
      }

      Array.from(document.querySelectorAll('.item')).forEach(function (button) {

        var id = button.id
        var elm = id;
        var elementTop = document.querySelector('#' + elm).getBoundingClientRect().top;
        var elementBottom = elementTop + document.querySelector('#' + elm).clientHeight;
        var viewportTop = Math.round(window.scrollY);
        var viewportBottom = viewportTop + window.screen.height;
        if (elementBottom > viewportTop && elementTop < viewportBottom) {

          var tagging = document.getElementById(elm).getAttribute('data-tagging');
          if (tagging) {
            /*var scrollTagging =JSON.parse(tagging);
 
            window.dataLayer.push({ ...JSON.parse(tagging)}); */
          }
          if (elm <= 1) {

            document.querySelector('.main-menu')?.classList.remove('main-menu-hide');

          }
          if (elm > 2) {

            document.querySelector('.main-menu')?.classList.add('main-menu-hide');
          }

        }

      });


      if (bounding.top <= 10 && bounding.top >= 0) {
        document.querySelector('.main-menu')?.classList.remove('main-menu-hide');
      }
      let allElements = document.querySelector('#brandhubscroll');
      if (this.isTrigger) {
        let _self = this;
        allElements.childNodes.forEach(function (ele, index) {
          let elementId = "index" + index;
          function callback(entries) {
            entries.forEach((entry) => {
              let str = (index + 1).toString();
              if (_self.currentElementId != elementId && index != 0 && !_self.indexArray.includes(str)) {
                if (entry.isIntersecting) {
                  let tagging = ele.getAttribute("data-tagging");
                  window.dataLayer.push(JSON.parse(tagging));
                  _self.isTrigger = false;
                  _self.currentElementId = elementId;
                  let eventlabel = JSON.parse(tagging).eventLabel;
                  var result = eventlabel.split('::');
                  var slidenumber = result[0];
                  _self.indexArray.push(slidenumber);
                }
              }
            });
          };
          function createObserver(ele, callback) {
            const options = {
              root: null,
              threshold: 0.25
            };
            const observer = new IntersectionObserver(callback, options);
            observer.observe(ele);
          };
          createObserver(ele, callback);
        });
      }
    },

    taggingScroll(tagging) {
      window.dataLayer.push({ ...tagging });

    },
    bgimage(src, mobileimageUrl) {
      var imgurl = `background-image:url('${src}')`;
      if (this.smallScreen === true) {
        imgurl = `background-image:url('${mobileimageUrl}')`;
      }
      return imgurl;
    },
    tagging(tagging) {
      window.dataLayer.push({ ...tagging });

    },
    removeExstingclass(index) {
      this.$set(this.activeSliders, index, false);
      var allElements = document.querySelectorAll(".deactive-current-card");
      let i = 0;
      for (i = 0; i < allElements.length; i++) {
        allElements[i].classList.remove('deactive-current-card');
      }
      var allElementslider = document.querySelectorAll('.active-current-slider');
      let j = 0;
      for (j = 0; j < allElementslider.length; j++) {
        allElementslider[j].classList.remove('active-current-slider');
      }

    },
    initializeSwiper(index) {
      let updatedOptions = { ...this.swiperOptions }; // Default options
      const sliderLength = this.initialData.BrandHubSectionList[index]?.BrandHubSliderList || [];
      const sliderCount = sliderLength.length;

      if (sliderCount <= 2) {
        updatedOptions = {
          ...this.swiperOptions,
          centeredSlides: true, // Example override for small slider
        };
      } else {
        updatedOptions = {
          ...this.swiperOptions,
          centeredSlides: false, // Example override for larger slider
        };
      }
      this.$nextTick(() => {
        if (this.swiperInstance) {
          this.swiperInstance.destroy(true, true);
          this.swiperInstance = null;
        }
        this.swiperInstance = new Swiper(`.swiper-${this.dynamicClass}${index}`, {
          ...updatedOptions,
          on: {
            init: () => {
              this.updateNavigationState(); // Call after Swiper is initialized
            },
            slideChange: () => this.updateNavigationState(),
            reachBeginning: () => this.updateNavigationState(),
            reachEnd: () => this.updateNavigationState(),
          },
        });
      });

    },
    addclass(index) {
      /********/
      Object.keys(this.activeSliders).forEach((key) => {
        this.activeSliders[key] = false;
      });
      // Show the selected slider
      this.$set(this.activeSliders, index, true);
      // Initialize Swiper when the slider becomes visible
      this.initializeSwiper(index);
      this.removeExstingclass();
      document.querySelectorAll('.brand_content__hub__section')[index].classList.add('deactive-current-card--');
      let self = document.querySelectorAll('.brand_content__hub__section')[index]
      self.querySelector('.brand_content__hub__section__item').classList.add('deactive-current-card');
      self.querySelector('.brand_content__hub__slider').classList.add('active-current-slider');
      self.querySelector('.brand_content__hub__slider').classList.remove('hide');

    },
    isUsingKeyboard() {
      return document.body.classList.contains('is-using-keyboard');
    },
    updateNavigationState() {
      if (!this.swiperInstance) {
        return; // Ensure Swiper instance exists
      }

      const prevButton = document.querySelector('.carousel__arrow---left');
      const nextButton = document.querySelector('.carousel__arrow---right');

      if (this.swiperInstance.isBeginning) {
        prevButton?.classList.add('disabled');
      } else {
        prevButton?.classList.remove('disabled');
      }

      if (this.swiperInstance.isEnd) {
        this.swiperAtBegain = true;
        nextButton?.classList.add('disabled');
      } else {
        nextButton?.classList.remove('disabled');
      }
    },
    videoLoaded() {
      this.$nextTick(() => {
        const hubVideoComponent = document.querySelector('.hub-video__container');
        if (hubVideoComponent) {
          this.hubVideoDisplay = true;
          setTimeout(function () {
            document.querySelector(".scroll-snap-container").scrollTo(0, 0);
          }, 10);

        }
      });
    }
  }

}
</script>
<style lang="scss" src="./brand-content-hub.scss"></style>
<style lang="scss" src="./aos.scss"></style>