import { truncate } from '../ellipsis/ellipsis';
/** The fallback truncate length */
export const defaultTruncateLength = 350;

/**
 * @desc Returns a string with three dots if it exceeds the limit given
 * @function truncate
 * @param {string} html - The html to truncate
 * @param {number} limit - The limit after the string will be truncated
 * @return {html} The truncated html
 */
export function truncateHtml(html, limit = defaultTruncateLength, showDots = true) {
  const container = document.createElement('div');
  container.innerHTML = html;

  let totalChars = 0;
  const dots = showDots ? '...' : '';

  function truncateNode(node) {
    if (totalChars >= limit) return true;

    if (node.nodeType === Node.TEXT_NODE) {
      let remaining = limit - totalChars;
      if (node.textContent.length > remaining) {
        node.textContent = node.textContent.substring(0, remaining) + dots;
        totalChars = limit;
        return true;
      } else {
        totalChars += node.textContent.length;
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      for (let i = 0; i < node.childNodes.length; i++) {
        if (truncateNode(node.childNodes[i])) {
          while (node.childNodes.length > i + 1) {
            node.removeChild(node.childNodes[i + 1]);
          }
          return true;
        }
      }
    }
    return false;
  }

  truncateNode(container);

  return container.innerHTML;
}

export function getPlainText(html) {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || '';
}

export const truncateRichText = {
  name: 'truncate-rich-text',
  bind: (el, binding) => {
    const element = el;
    const truncateLength = binding.value.limit;
    const originalContent = el.innerHTML;
    const plainText = getPlainText(originalContent);
    const singleLine = binding.value.singleLine ? binding.value.singleLine : false;
    if (plainText.length <= truncateLength) {
      return;
    }
    /** Replace the innerhtml with the truncated string */
    if (singleLine) {
      const string = el.textContent;
      const dots = binding.value.ellipse ? '...' : '';
      element.innerHTML = truncate(string, truncateLength, dots);
    } else {
      element.innerHTML = truncateHtml(originalContent, truncateLength, binding.value.ellipse);
    }
  },
};
