<template>
  <div class="routine-finder-your-routine__product-points__container">
    <div ref="listElement" class="routine-finder-your-routine__product-points">
      <ul>
        <li v-html="productListData.descriptionDetails.recommendationDescription1"></li>
        <li v-html="productListData.descriptionDetails.recommendationDescription2"></li>
        <li v-html="productListData.descriptionDetails.recommendationDescription3"></li>
      </ul>
    </div> 
    <div class="routine-finder-your-routine__product-btns">
      <button @click="redirectToPdp(productListData.url)"
        v-truncate-rich-text="{ limit: 15, ellipse: false, singleLine: true }" class="findOutMore">{{productListData.findmorectalabel}}</button>
      <!-- <button v-truncate-rich-text="{ limit: 12, ellipse: false, singleLine: true }"
        @click.stop.prevent="openRetailer()" class="buyBtn">{{productListData.buynowctalabel}}</button>  iscommerceconnectorenabled-->
        
        <routine-finder-commerce-connector :cta-lable="productListData.buynowctalabel" :eretailer="productListData.commerceConnectorDetails" v-if="productListData.iscommerceconnectorenabled"></routine-finder-commerce-connector>
        <routine-finder-product-retailer :cta-lable="productListData.buynowctalabel" :error-msg="productListData.errormessage" :retailerdata="productListData.allRetailersList" v-if="productListData.isretailersenabled"> </routine-finder-product-retailer>
    </div>
  </div>
</template>
<script>
import { truncateRichText } from '@Foundation/directives/truncateRichText/truncateRichText';
import routineFinderProductRetailer from './routine-finder-product-retailer.vue';
import routineFinderCommerceConnector from './routine-finder-commerce-connector.vue';
export default {
  name: 'routine-finder-result-product-list',
  components:{routineFinderProductRetailer,routineFinderCommerceConnector},
  directives: {
    truncateRichText
  },
  props: {
    productListData: {
      type: [String, Object],
      required: true,
      default: undefined
    },
  },
  methods: {
    redirectToPdp(item) {
      window.open(item, '_blank');
    },
    openRetailer() {
      console.log("buy");
    }
  }
};
</script>

<style lang="scss" src="./routine-finder-result-product-list.scss"></style>