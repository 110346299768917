<template>
  <header class="routine_finder__header">
    <div class="routine_finder__header__container">
      <div class="routine_finder__header__back">
        <button v-if="getCurrentIndex > 0" @click="prev()"><span class="back-arrow"></span> {{ headerdata.before
          }}</button>
      </div>
      <div class="routine_finder__header__logo">
        <img :src="this.headerdata.image.image_url" :alt="headerdata.image.image_alt" />
      </div>
      <div class="routine_finder__header__exit">
        <button @click="closeRoutineFinder()"><span class="back-arrow">{{ headerdata.exit }}</span></button>
      </div>
    </div>
    <routine-finder-exit-popin v-if="showExitPopin"></routine-finder-exit-popin>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import routineFinderExitPopin from '@Feature/RoutineFinder/code/Scripts/components/routine-finder-popin/routine-finder-exit-popin.vue';
export default {
  name: 'routine-finder-header',
  components: { routineFinderExitPopin },
  props: {
    headerdata: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      exitPopin: false,
      showExitPopin: false,
    }
  },
  computed: {
    ...mapGetters(['getCurrentIndex', 'getQuestionList']),

  },
  mounted() {
    window.eventBus.$on('letstartCta:on', (value) => {
      this.exitPopin = true;
    });
    window.eventBus.$on('rf-close-exit-popin', (value) => {
      this.showExitPopin = false;
    });
  },
  methods: {
    closeRoutineFinder() {
      if (!this.exitPopin) {
        this.showExitPopin = false;
        this.$store.commit('resetQuestionAnsDatas');
        const animationClass = document.querySelector(".routine_finder__modal")
        if (animationClass) {
          animationClass.classList.remove('animationend');
        }
        setTimeout(() => {
          window.eventBus.$emit('routineFinderClose:on', false);
        }, 100);
      } else {
        this.showExitPopin = true;
      }

    },
    prev() {
      if (this.getCurrentIndex > 0) {
        setTimeout(() => {
          window.eventBus.$emit('rf-final-question', false);
        }, 100);
        this.$store.commit('setCurrentIndex', 'prev');
      }
    }
  }
};
</script>
<style lang="scss" src="./routine-finder-header.scss"></style>