import { render, staticRenderFns } from "./routine-finder-why-do-we-ask.vue?vue&type=template&id=81cd2b06"
import script from "./routine-finder-why-do-we-ask.vue?vue&type=script&lang=js"
export * from "./routine-finder-why-do-we-ask.vue?vue&type=script&lang=js"
import style0 from "./routine-finder-why-do-we-ask.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports