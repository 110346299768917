<template>
  <div class="routine-finder-loader__container" :aria-label="this.bannerAlt"
    :style="{ backgroundImage: this.currentBackground }">
    <div v-if="this.loadingIcon" class="loader-container">
      <div class="loadMore-trigger"></div>
      <transition name="fade">
        <div class="loader-css">Loading</div>
      </transition>
    </div>
    <div class="loaderScreen-overlay" v-if="overlay"></div>
    <div class="routine-finder-loader__content">
      <h2 class="heading-h2 routineFinder-h2" v-html="this.apiData.loader_title" v-if="this.apiData.loader_title"></h2>
      <div class="routine-finder-loader__points">
        <ul>
          <li v-for="(item, index) in loadedItems" :key="index">
            <div class="loading-wrapper" v-if="item.loading">
              <img :src="gifLoaderImg.image_url" :alt="gifLoaderImg.image_alt" />
              <div class="LoadingText" v-show="index === 3" >{{ item.text }}</div> 
            </div>
            <div class="routine-items" v-else>
              <div class="tickIcon"><img :src="item.tickimage" :alt="item.tickImageAlt" /></div>
              <div class="youtineText" v-show="index !== 3">{{ item.text }}</div>
              <div class="youtineText" v-show="index === 3"> {{item.buildroutinTxt}}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'routine-finder-loader-screen',
  data() {
    return {
      loadingIcon: true,
      loadedItems: [],
      activeIndex: 0,
      currentBackground: '',
      activeList: false,
      bannerAlt: '',
      gifLoaderImg: '',
      loadingText: '',
      questions: '',
      answers: '',
      questionsImages: '',
      apiData: [],
      overlay:false,
    }
  },
  created() {
    this.fetchData();
    window.addEventListener('resize', this.setLoadingBackground);
  },
  computed: {
    ...mapGetters(['getAnswerDataList']),
    questionTypes() {
      return this.questions.map(question => question.question_type);
    },
    questionTypesImage() {
      return this.questions.map(question => question.questions_type_image);
    },
    answersTypes() {
      return this.getAnswerDataList.map(answer => answer.answerValue);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setLoadingBackground);
  },
  mounted() { 
    document.querySelector('body').classList.add("loaderScreen");
    window.eventBus.$emit('routineFinderLoadingScreen:on', true);
  },
  methods: {

    async fetchData() {
      const apiURL = '/api/routinefinder/RoutineFinderApiBus';
      const jsonData = {
        "Stage": 3
      };
      try {
        const response = await fetch(apiURL, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json;charset=utf-8' },
          body: JSON.stringify(jsonData),
        });
        if (response.ok) {
          const data = await response.json();
          this.apiData = data.data;
          this.gifLoaderImg = this.apiData.build_routine_image;
          this.loadingText = this.apiData.build_your_routine
          this.questions = this.apiData.questions_types;
          this.loadItemsSequentially();
          this.setLoadingBackground();
          this.loadingIcon = false;
          this.overlay= true;
        }
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    },
    async loadItemsSequentially() {
      for (let i = 0; i < this.questionTypes.length; i++) {
        this.loadedItems.push({ loading: true, text: `${this.loadingText}` });
        await new Promise(resolve => setTimeout(resolve, 1500));
        setTimeout(() => {
          this.$set(this.loadedItems, i, { loading: false, text: this.questionTypes[i].concat(' ').concat(this.answersTypes[i]), tickimage: this.questionTypesImage[i].image_url, tickImageAlt: this.questionTypesImage[i].image_alt , buildroutinTxt: this.loadingText});
         }, 50);
      }
      setTimeout(() => {
        window.eventBus.$emit('routineFinderresultpage:on', true);
        document.querySelector('body').classList.remove("loaderScreen");
      }, 1000);
    },
    setLoadingBackground() {
      if (window.innerWidth <= 768) {
        this.currentBackground = `url('${this.apiData.background_image_mobile.image_url}')`;
        this.bannerAlt = this.apiData.background_image_mobile.image_alt;
      } else {
        this.currentBackground = `url('${this.apiData.background_image_desktop.image_url}')`
        this.bannerAlt = this.apiData.background_image_desktop.image_alt;
      }
    },
  }
};
</script>
<style lang="scss" src="./routine-finder-loader-screen.scss"></style>