// directives/lazy-load.js
export const lazyLoad = {
  inserted(el) {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const video = el;
          // Set the source of the video when it enters the viewport
          video.src = video.dataset.src;
          // Stop observing once the video has been loaded
          observer.unobserve(el);
        }
      },
      {
        rootMargin: '0px 0px 200px 0px', // Trigger earlier to load before it's fully visible
      }
    );
    observer.observe(el);
  }
};
