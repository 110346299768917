<template>
  <div>
    <div class="routine_finder_info__modal" :class="this.openInfoModal ? 'slideActive' : ''">
      <div ref="scrollableElement" class="routine_finder_info__innerWrapper">
        <div class="routine_finder_info__close">
          <div @click="closeInfoModal()"><svg width="43" height="41" viewBox="0 0 43 41" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <line x1="2.81109" y1="1.18891" x2="41.8111" y2="40.1889" stroke="black" stroke-width="2.29412" />
              <line x1="1.18891" y1="40.1889" x2="40.1889" y2="1.18891" stroke="black" stroke-width="2.29412" />
            </svg></div>
        </div>
        <div class="routine_finder_info__content">
          <h3 class="heading-h3" v-html="modalData.question"></h3>
          <div class="routine_finder_info__desc__container">
            <div class="modal-description mainDesc" v-if="modalData.question && modalData.description"
              v-html="modalData.description">
            </div>
            <div class="routine_finder_info__titleDesc" v-if="modalData.answers" v-for="answer in modalData.answers">
              <span class="modal-title" v-if="answer.title" v-html="answer.title"> </span>
              <p class="modal-description routineFinder-p" v-if="answer.description" v-html="answer.description"> </p>
            </div>


          </div>
        </div>
        <div class="routine_finder_info__cta">
          <button class="cta cta--light" @click="closeInfoModal()">{{ modalData.back_to_answer }}</button>
        </div>
      </div>

    </div>
    <div class="routine_finder_info__overlay" v-if="routineFinderInfoOverlay" @click="closeInfoModal()"></div>
  </div>
</template>

<script>

export default {
  name: 'routine-finder-info-modal',
  props: {
    modalData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      openInfoModal: true,
      routineFinderInfoOverlay: true,
      smallScreen: window.matchMedia('(max-width: 650px)').matches
    }
  },
  mounted() {
    const scrollElement = this.$refs.scrollableElement;
    if (!this.smallScreen && scrollElement.scrollHeight > scrollElement.clientHeight) {
      scrollElement.classList.add("noFixedBtn")
    }
  },
  methods: {
    closeInfoModal() {
      this.openInfoModal = false;
      window.eventBus.$emit('routineFinderInfoModalClose:on', false);
      this.routineFinderInfoOverlay = false;
    },
  }
};
</script>
<style lang="scss" src="./routine-finder-info-modal.scss"></style>
