<template>
    <div class="routine-finder-final-question">
        <modal class="routine-finder-final-question__container--modal" :config="'rf-final-question'" ref="modal">
            <div class="routine-finder-final-question__header-section">
                <div class="routine-finder-final-question__header-section_title" v-if="getFinalQuestion.question"
                    v-truncate-rich-text="{ limit: 70, ellipse: false, singleLine: false }"
                    v-html="getFinalQuestion.question">
                </div>
            </div>
            <div class="routine-finder-final-question__des-section">
                <div class="routine-finder-final-question__des-section__items">
                    <div class="routine-finder-final-question__des-section__items_list"
                        v-for="(items, index) in getFinalQuestion.answers " :key="index"
                        @click="active(index, items.option, items.answer_id,items.answer_title)"
                        :class="{ '--active': selectedIndex === index }">
                        <div class="routine-finder-final-question__des-section__items_list--img">
                            <img :src="items.image.image_url" :alt="items.image.image_alt">
                        </div>
                        <div class="routine-finder-final-question__des-section__items_list--text">
                            <div class="routine-finder-final-question__des-section__items_list--text-title">
                                {{ items.answer_title }}
                            </div>
                            <div class="routine-finder-final-question__des-section__items_list--text-des"
                                v-truncate-rich-text="{ limit: 50, ellipse: false, singleLine: false }">{{
                                    items.answer_description }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="routine-finder-final-question__footer-section">
                <div class="routine-finder-final-question__footer-section__cta-placeholder">
                    <button class="cta cta--light" :class="{ 'disabled': selectedIndex === null }" @click="closeModal"
                        :disabled="selectedIndex === null">{{ getFinalQuestion.final_question_cta }}</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '@Feature/Modal/code/Scripts/components/modal';
import { truncateRichText } from '@Foundation/directives/truncateRichText/truncateRichText';
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
export default {
    name: 'routine-finder-final-question',
    components: {
        Modal,
    },
    directives: {
        truncateRichText
    },
    props: {
        title: {
            type: String,
            default: 'WHAT SIZE ROUTINE SUITS <strong>YOUR LIFESTYLE BEST?</strong>',
        },
    },
    data() {
        return {
            selectedIndex: null,
            answerTitle:'',
        }
    },
    computed: {
        ...mapGetters(['getFinalQuestion', 'getAnswerIds', 'getQuestionCount']),
    },
    mounted() {
        if (this.$refs.modal && this.$refs.modal.$data) {
            this.$refs.modal.$data.showModal = true;
        }
    },
    methods: {
        closeModal() {
            if (this.$refs.modal && this.$refs.modal.$data) {
                this.gaTagging();
                this.$refs.modal.$data.showModal = false;
                window.eventBus.$emit('routineFinderresultpage:on', true);
                window.eventBus.$emit('rf-final-question', false);
            }
        },
        active(index, answerID, answer_id,answerTitle) {
            this.selectedIndex = index;
            this.answerTitle = answerTitle;
            let questionImportColumn = this.getFinalQuestion.import_column
            this.$store.commit('setAnswerIdsAtIndex', [this.getQuestionCount, answerID, answer_id, questionImportColumn]);

        },
        gaTagging() {      
            let ctaName =  this. getFinalQuestion.final_question_cta ?   this.getFinalQuestion.final_question_cta.toLowerCase() : '';
            let tagging = this.getFinalQuestion.final_question_tagging;
            tagging.eventAction = ctaName
            tagging.cta_name = ctaName;
            tagging.event_detail = this.answerTitle.toLowerCase();
          AnalyticsHandler.pushDataLayer(tagging );

    }
    },
};
</script>
<style lang="scss" src="./routine-finder-final-question.scss"></style>
