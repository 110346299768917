<template>
  <div v-if="initialData">
    <div class="routine-finder__whyAsk__container" v-if="!initialData.is_question" @click="initNoteSureModal()">
      <div class="routine-finder__whyAsk__inner-wrapper">
        <div class="routine-finder__whyAsk__icon" v-if="initialData.image.image_url"><img
            :src="initialData.image.image_url" :alt="initialData.image.image_alt" /></div>
        <div class="routine-finder__whyAsk__details">
          <h3 class="heading-h3" v-if="initialData.question_info.question_cta_text">
            {{ initialData.question_info.question_cta_text }}</h3>
        </div>
        <div class="arrow-icon">
          <svg width="40" height="8" viewBox="0 0 40 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M39.3536 4.35355C39.5488 4.15829 39.5488 3.84171 39.3536 3.64645L36.1716 0.464466C35.9763 0.269204 35.6597 0.269204 35.4645 0.464466C35.2692 0.659728 35.2692 0.976311 35.4645 1.17157L38.2929 4L35.4645 6.82843C35.2692 7.02369 35.2692 7.34027 35.4645 7.53553C35.6597 7.7308 35.9763 7.7308 36.1716 7.53553L39.3536 4.35355ZM0 4.5L39 4.5V3.5L0 3.5L0 4.5Z"
              fill="black" />
          </svg>
        </div>
      </div>
    </div>
    <div class="routine-finder__whyAsk__container with-desc" v-if="initialData.is_question"
      @click="initNoteSureModal()">
      <div class="routine-finder__whyAsk__inner-wrapper">
        <div class="routine-finder__whyAsk__icon" v-if="initialData.image.image_url"><img
            :src="initialData.image.image_url" :alt="initialData.image.image_alt" /></div>
        <div class="routine-finder__whyAsk__details">
          <h3 class="heading-h3" v-if="initialData.question_info.title"> {{ initialData.question_info.title }}
          </h3>
          <p v-if="initialData.question_info.description" class="routineFinder-p"
            v-truncate-rich-text="{ limit: 120, ellipse: false, singleLine: false }"
            v-html="initialData.question_info.description"></p>
        </div>
        <div class="arrow-icon" v-if="initialData.question_info.description === ''">
          <svg width="40" height="8" viewBox="0 0 40 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M39.3536 4.35355C39.5488 4.15829 39.5488 3.84171 39.3536 3.64645L36.1716 0.464466C35.9763 0.269204 35.6597 0.269204 35.4645 0.464466C35.2692 0.659728 35.2692 0.976311 35.4645 1.17157L38.2929 4L35.4645 6.82843C35.2692 7.02369 35.2692 7.34027 35.4645 7.53553C35.6597 7.7308 35.9763 7.7308 36.1716 7.53553L39.3536 4.35355ZM0 4.5L39 4.5V3.5L0 3.5L0 4.5Z"
              fill="black" />
          </svg>
        </div>
      </div>
      <div class="routine-finder__whyAsk__learnmore" v-if="initialData.question_info.information_cta_text">
        <span>{{ initialData.question_info.information_cta_text }}</span>
        <div class="arrow-icon"><svg width="40" height="8" viewBox="0 0 40 8" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M39.3536 4.35355C39.5488 4.15829 39.5488 3.84171 39.3536 3.64645L36.1716 0.464466C35.9763 0.269204 35.6597 0.269204 35.4645 0.464466C35.2692 0.659728 35.2692 0.976311 35.4645 1.17157L38.2929 4L35.4645 6.82843C35.2692 7.02369 35.2692 7.34027 35.4645 7.53553C35.6597 7.7308 35.9763 7.7308 36.1716 7.53553L39.3536 4.35355ZM0 4.5L39 4.5V3.5L0 3.5L0 4.5Z"
              fill="black" />
          </svg></div>
      </div>

    </div>
    <routine-finder-info-modal v-if="routineFinderInfoModal && initialData.pop_in"
      :modal-data="initialData.pop_in"></routine-finder-info-modal>
  </div>
</template>

<script>
import { truncateRichText } from '@Foundation/directives/truncateRichText/truncateRichText';
import routineFinderInfoModal from '@Feature/RoutineFinder/code/Scripts/components/routine-finder-modal/routine-finder-info-modal.vue';
export default {
  name: 'routine-finder-why-do-we-ask',
  components: { routineFinderInfoModal },
  directives: {
    truncateRichText
  },
  props: {
    questionNumber: {
      type: Number
    },
    initialData: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      routineFinderInfoModal: false,
    }
  },
  mounted() {
    window.eventBus.$on('routineFinderInfoModalClose:on', (value) => {
      this.routineFinderInfoModal = false;
    });
  },
  methods: {
    initNoteSureModal() {
      this.routineFinderInfoModal = true;
    }
  }
};
</script>
<style lang="scss" src="./routine-finder-why-do-we-ask.scss"></style>