<template>
  <span  data-tag-wrapper 
  v-tag:promotionclick.dom='{ label: `none::${this.removeHTMLTags(this.contentPush.Description).toLowerCase()}` }'
   v-tag:promotionimpression.dom='{label: `${pageCategory}`}' >
    <div data-tag-promotion-identifier v-bind="dataTagging" class="product-push">
      <template v-if="contentPush.Packshot.MainImage" >
        <responsive-image aspect-ratio="56" :srcset="contentPush.Packshot.MainImage" animate="">
          <!-- Accessibility -->
          <img :src="contentPush.Packshot.MainImage" :alt="contentPush.Packshot.ImageAlt" />
          <noscript>
            <!-- SEO -->
            <img :src="contentPush.Packshot.MainImage" :alt="contentPush.Packshot.ImageAlt" />
          </noscript>
        </responsive-image>
      </template>
      <div class="product-push__content">
        <div class="product-push__desc" v-html="decodeHtml(contentPush.Description)"></div> 
        <a :href="contentPush.CTALink" class="cta--secondary cta--dark cta--alt" v-if="contentPush.CTAText && !contentPush.EnableRoutineFinder" >
          <span v-html="contentPush.CTAText" />
        </a>
        <button v-if="contentPush.EnableRoutineFinder && contentPush.CTAText" class="cta--secondary cta--dark cta--alt" @click="openCpRoutineFinder()"><span v-html="contentPush.CTAText" /> </button>
      </div>
    </div>
    <routine-finder v-if="showoCpRoutineFiner"></routine-finder> 
  </span>
</template>
<script>
 import { tag } from '@Foundation/analyticsHandler';
import ResponsiveImage from '@Feature/ResponsiveImage/code/Scripts/components/responsive-image.vue';
import routineFinder from '@Feature/RoutineFinder/code/Scripts/components/routine-finder.vue';
export default {
  name: 'content-push',
  components: { ResponsiveImage, routineFinder },
  directives: {
      tag
  },
  props: {
    contentPush: {
      type: Object,
      required: false,
    },
    position: {
        type: Number
    },
    pageCategory: {
        type: String,
        default:'product selector page'
    }
  },
  data() {
    return {
      showoCpRoutineFiner:false,
    }
  },
  computed: {
    dataTagging() {
      const attributes = {
        "data-tag-promotion-creative":"skyscraper",
        "data-tag-promotion-id":"none",
        "data-tag-promotion-name":this.removeHTMLTags(this.contentPush.Description).toLowerCase(),
        "data-tag-promotion-position":this.position + 1,
      };
      return attributes;
    },
  },
  mounted() {
    if (document.querySelectorAll('.trackImpfour').length) {
        var rx = document.querySelector('.trackImpfour').getBoundingClientRect().x;
        var impclassName = 'impression';
        var m = 0;
        document.querySelectorAll('.trackImpfour').forEach((element,index) => {
          const rect = element.getBoundingClientRect();
          if (rx != rect.x) {
            element.classList.add(impclassName);
          } else {
            impclassName = 'impression-' + m;
            element.classList.add(impclassName);
            m++;
          }
        });
    }
    window.eventBus.$on('routineFinderClose:on', (value) => { 
      setTimeout(() => {
      this.showoCpRoutineFiner = value; 
      }, 300);
    });
  },
  methods: {
    openCpRoutineFinder(){
      this.showoCpRoutineFiner = true;
    },
    decodeHtml(html) {
      const txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    },
    removeHTMLTags(html) {
        let div = document.createElement("div");
        div.innerHTML = html;
        let datas=div.innerText.replaceAll('\n', ' ').trim();
        var parser = new DOMParser();
        var parsedHtml = parser.parseFromString(datas, 'text/html');
        var withoutBrTags = parsedHtml.body.textContent || "";
        return withoutBrTags;
    }
  },
};
</script>
<style lang="scss" src="./contentPush.scss"></style>
