<template>
    <div class="routine-finder-your-routine__container">
        <div v-for="(category, index) in resultProductData" :key="index">
            <div class="routine-finder-your-routine__wrapper">
                <div class="routine-finder-your-routine__num-routine">
                    <span>{{ index + 1 }}</span>
                </div>
                <div class="routine-finder-your-routine__product-routine">
                    <h3 class="heading-title">{{ category.stepsRowValue }}</h3>
                </div>
            </div>
            <div v-for="(item, index) in category.routineProductDetailsList" :key="index" >
                <div class="routine-finder-your-routine__product-List"  @click="redirectToPdp(item.url)">
                    <div class="routine-finder-your-routine__product-List_wrapper">
                    <div class="routine-finder-your-routine__product-image">
                        <img :src='item.image.mainImage' :alt="item.image.imageAlt" />
                    </div>
                    <div class="routine-finder-your-routine__product-details">
                        <h3 class="product-title" v-html="item.title">
                        </h3>
                        <div class="routine-finder-your-routine__product-ratingStar">
                            <star-rating :increment="0.01" :max-rating="item.ratingCount" :inactive-color="'#fff'"
                                :active-color="'#009EE2'" :border-color="'#009EE2'" :border-width="1" :star-size="16"
                                :rating="item.averageCount" :read-only="true" :show-rating="false" aria-hidden="true" />
                            <span>{{ item.averageCount + '/' + item.ratingCount }}</span>
                        </div>
                        <div class="productList-desktopView"> <routine-finder-result-product-list
                                :product-list-data="item"></routine-finder-result-product-list>
                        </div>
                    </div>
                    </div>
                    <div class="routine-finder-your-routine__dayNight-icon">
                        <img @click.stop="preventclick(event)" v-if="item.sunmoonimage.sunimage" :src='item.sunmoonimage.sunimage' alt="" />
                        <img @click.stop="preventclick(event)" v-if="item.sunmoonimage.moonimage" :src='item.sunmoonimage.moonimage' alt="" />
                    </div>
                    <div class="productList-mobileView" @click="redirectToPdp(item.url)">
                        <routine-finder-result-product-list
                            :product-list-data="item"></routine-finder-result-product-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StarRating from 'vue-star-rating';
import routineFinderResultProductList from '@Feature/RoutineFinder/code/Scripts/components/routine-finder-result-page/routine-finder-result-product-list.vue';
export default {
    name: 'routine-finder-your-product-result',
    components: { routineFinderResultProductList, StarRating },
    props: {
        resultProductData: {
            type: [String, Object],
            required: true,
            default: undefined
        },
    },
    data() {
        return {
            smallScreen: window.matchMedia('(max-width: 1024px)').matches,
        }
    },
    methods: {
        redirectToPdp(item) {
            window.open(item, '_blank');
        },
        preventclick(event){
            event.preventDefault();
        }
    }

}
</script>
<style lang="scss" src="./routine-finder-your-product-result.scss"></style>