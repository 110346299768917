<template>
  <div ref="videoParent" id="videoParent">
  </div>
</template>
<script>

export default {
  name: 'brand-content-hub-video',
  components: {},
  data() {
    return {
      loadingIcon: true
    };
  },
  mounted() {
    this.$nextTick(() => {
      const hubVideoComponent = document.querySelector('.hub-video__container');
      const brandVideoComponent = this.$refs.videoParent;
      if (hubVideoComponent && brandVideoComponent) {
        brandVideoComponent.appendChild(hubVideoComponent);
      }
      const videoElement = brandVideoComponent.querySelector('video');
      const getDataSrc = videoElement?.dataset.src;
      if (getDataSrc) {
        videoElement.setAttribute('src', getDataSrc);
        setTimeout(() => {
          this.$refs.videoParent.querySelector('video').play()
        }, 500);
      }
    });
  },
}
</script>